import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/SEO";
import Header from "../components/layout/Header.js";
import SiteTitleQuery from "../components/SiteTitleQuery";
import GDPR from "../images/svgFiles/GDPR.svg";
// import ProfileCard from "../components/ProfileCard.js";
// import Lise from "../images/profiles/profileLise.jpg";

import ProfileCardNoImg from "../components/ProfileCardNoImg.js";

function Policy() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Integritetspolicy"
            keywords={[
              `GDPR`,
              `policy`,
              `integritetspolicy`,
              `cookie`,
              `privacy`,
              `personuppgifter`
            ]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img className="w-full" src={GDPR} alt="gdpr" title="gdpr"></img>
              <div className="px-6 py-4">


                <div className="font-bold text-xl mb-2">Extern integritetspolicy</div>
                <div className="text-gray-700 text-base">
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Personuppgifter enligt (EU)2016/279,
                    <br></br>
                    den allmänna dataskyddsförordningen (GDPR)
                    <br></br>
                    <br></br>

                  </p>
                  PSK Syd AB, även nedan kallat PSK är ett svenskt konsult- och
                  mjukvaruföretag med huvudkontor i Helsingborg.
                  Vi värnar om dina personuppgifter och vi lägger därför stor vikt
                  vid att skydda din integritet. Genom att använda vår hemsida
                  eller nyttjar våra tjänster, godkänner du att din personliga
                  information behandlas i enlighet med denna integritetspolicy.
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Vem ansvarar för behandlingen av personuppgifter?
                  </p>
                  PSK Syd AB, <br></br>
                  Garnisonsgatan 7A  , <br></br>
                  254 66 Helsingborg, <br></br>
                  org.nr 556477-8222, <br></br>
                  ansvarar för de personuppgifter som du lämnar till oss via vår
                  hemsida{" "}
                  <a
                    href="https://psksyd.com"
                    className="text-blue-600"
                    target="_blank"
                  >
                    {" "}
                    https://psksyd.com
                  </a>
                  ,<br></br>
                  e-post och/eller telefon.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Vad är personuppgifter?
                  </p>
                  Personuppgifter är information om en identifierad eller
                  identifierbar fysisk levande person. Det kan exempelvis vara
                  namn, adress, e-postadress, telefonnummer, IP-adresser eller
                  fotografier.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Vilka personuppgifter behandlar vi på PSK Syd AB?
                  </p>
                  De personuppgifter som PSK behandlar är uppgifter som du har
                  lämnat till oss eller som vi har samlat in från dig eller en
                  tredje part som vi har avtal med.
                  <br></br>
                  Om du är kontaktperson åt ett företag eller en organisation
                  som PSK har ett uppdrag för behandlar vi namn,
                  kontaktuppgifter (företagsadress, ort, telefonnummer och
                  e-post) och din typ av befattning på företaget.
                  <br></br>
                  <br></br>
                  När du besöker oss på{" "}
                  <a
                    href="https://psksyd.com/career"
                    className="text-blue-600"
                    target="_blank"
                  >
                    {" "}
                    https://psksyd.com/career
                  </a>{" "}
                  kan du lämna personuppgifter som namn, e-postadress, bifoga ditt cv och/eller personliga brev.
                  <br></br>
                  Genom att aktivt genomföra denna handling godkänner du vår
                  behandling av dina personuppgifter och vi kommer spara dessa i
                  2 år enligt gällande lagstiftning.
                  <br></br>
                  <br></br>
                  Vi samlar även in hur våra besökare använder
                  <a
                    href="https://psksyd.com"
                    className="text-blue-600"
                    target="_blank"
                  >
                    {" "}
                    https://psksyd.com
                  </a>
                  och hur användarna upplever informationen, via din IP-adress,
                  information om dina webbläsarinställningar och hur ditt
                  operativsystem använder sidorna. Denna information behandlas
                  med stöd av intresseavvägning och används för statistik och
                  för att utveckla webbplatsens innehåll och funktioner.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Syfte med behandlingen av personuppgifter?
                  </p>
                  Den information vi samlar in från dig används för följande
                  ändamål:
                  <br></br>
                  <br></br>
                  <ul>
                    <li>
                      <b>Fullgöra avtal</b>
                      <br></br>
                      För att vi ska kunna leverera den tjänst och/eller produkt
                      som tillhandahålls av oss.
                    </li>
                    <br></br>
                    <li>
                      <b>För att fullgöra rättsliga förpliktelser</b>
                      <br></br>
                      Vi behandlar endast dina personuppgifter så länge det är
                      relevant för det syfte som det ursprungligen samlades in
                      för. Efter denna tid sparas endast uppgifter om dig
                      utifrån legala krav som till exempel arkiveringslag,
                      bokföringslag eller skattelagstiftning.
                    </li>
                    <br></br>
                    <li>
                      <b>Kundkommunikation</b>
                      <br></br>
                      Den namnuppgift, e-postadress och det telefonnummer du
                      anger kan användas av oss för att kontakta dig i olika
                      ärenden
                      <br></br>
                      Dina kontaktuppgifter kan också komma till användning vid
                      utskick av enkäter gällande kvalitetssäkring exempelvis
                      kundnöjdhet samt när du kommunicerar med oss i övriga
                      digitala kanaler såsom Facebook, LinkedIn eller liknande.
                    </li>
                  </ul>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Mottagare av personuppgifterna{" "}
                  </p>
                  Vi säljer inte eller överför din personliga information till
                  tredje part <i>utanför</i> EU/EES utan din skriftliga tillåtelse.
                  Uppgifterna kan komma att delas med tredje part inom EU/EES i
                  syfte att kunna fullfölja våra åtaganden.
                  <br></br>
                  <br></br>
                  Dessa mottagare är betrodda leverantörer som hjälper oss att
                  hantera hemsidan, driva vår verksamhet samt leverera
                  förmedlade uppdrag. Med dessa parter finns biträdesavtal som
                  reglerar hur personuppgifterna får användas och hur de ska
                  skyddas.
                  <br></br>
                  <br></br>
                  Vi kan också dela din personliga information där vi finner det
                  nödvändigt för att följa lagstadgade krav eller skydda våra
                  eller andras rättigheter, egendom eller säkerhet.
                  <br></br>
                  <br></br>
                  Om dina personuppgifter kan komma delges med andra mottagare
                  än de som anges i denna integritetspolicy, kommer vi att ge
                  dig information om detta när vi samlar in dina personuppgifter
                  och du ger oss ditt samtycke.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">
                    Hur länge lagrar vi dina personuppgifter?{" "}
                  </p>
                  Uppgifterna lagras under den period som är avtalad eller
                  beslutad i förhållande till lagstiftningen, och vi
                  säkerställer radering av personuppgifterna när de inte längre
                  behövs för avsett ändamål. Perioden avgörs av ändamålet och
                  den lagliga grunden för behandlingen, det finns därför inte en
                  generell lagringsperiod som gäller för samtliga behandlingar.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <p className="font-bold text-lg mb-2">Cookies </p>
                  Vi samlar även in hur våra besökare som använder{" "}
                  <a
                    href="https://psksyd.com"
                    className="text-blue-600"
                    target="_blank"
                  >
                    {" "}
                    https://psksyd.com
                  </a>{" "}
                  och hur användarna upplever informationen, via din IP-adress,
                  information om dina webbläsarinställningar och hur ditt
                  operativsystem använder sidorna.
                  <br></br>
                  <br></br>
                  Denna information behandlas med stöd av intresseavvägning och
                  används för statistik och för att utveckla webbplatsens
                  innehåll och funktioner.
                  <br></br>
                  <br></br>
                  Cookies är små filer som en hemsida eller tjänsteleverantör
                  skickar till datorns hårddisk via webbläsaren (om du tillåter)
                  och som gör att hemsidan känner igen din webbläsare och kan
                  hämta och komma ihåg viss information. Vi använder cookies för
                  att förbättra användarupplevelsen på vår hemsida samt
                  sammanställa statistik om trafik och kommunikation.
                  <br></br>
                  <br></br>
                  Du kan också välja att stänga av cookies och/eller radera
                  lagrade cookies i din webbläsare. Observera att blockering av
                  cookies kan påverka sidans funktionalitet.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="font-bold text-lg mb-2">
                    Dina personliga rättigheter{" "}
                  </p>
                  Du har rätt att begära ett utdrag om vilka personuppgifter vi
                  har om dig genom att skriftligen begära ett registerutdrag.
                  <br></br>
                  <br></br>
                  Du har också rätt att begära att felaktiga uppgifter om dig ska
                  rättas, liksom möjligheten att begära att vi tar bort din
                  personliga information.
                  <br></br>
                  <br></br>
                  Du har rätt att när som helst
                  återkalla ditt samtycke till behandling av personuppgifter och
                  att förbjuda användningen av din personliga information för
                  direkt marknadsföring. Vi är då skyldiga att upphöra med
                  behandlingen utom i de fall där legala krav finns.
                  <br></br>
                  <br></br>
                  Du har, som registrerad rätt till dataportabilitet om vår rätt
                  att behandla dina personuppgifter grundar sig antingen på ditt
                  samtycke eller fullgörande av ett avtal med dig. En
                  förutsättning för dataportabilitet är att överföringen är
                  tekniskt möjlig och kan ske automatiserat.
                  <br></br>
                  <br></br>
                  Du har rätt att lämna eventuella klagomål på vår behandling av
                  dina personuppgifter till Integritetsskyddsmyndigheten
                  <a
                    href="https://www.imy.se/"
                    className="text-blue-600"
                    target="_blank"
                  >
                    {" "}
                    (IMY)
                  </a>{" "}
                  <br></br>
                  <br></br>
                  Om du har några frågor angående denna integritetspolicy, vår
                  behandling av personuppgifter eller vill utöva någon av dina
                  personliga rättigheter gör du detta skriftligen till oss.
                  <br></br>
                  Vi behandlar din förfrågan inom lagstadgad tid (30 dagar).
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="font-bold text-lg mb-2">
                    Hur kontaktar du oss?{" "}
                  </p>
                  PSK Syd AB, <br></br>
                  Garnisonsgatan 7A, <br></br>
                  254 66 Helsingborg, <br></br>
                  <a href="mailto:info@psksyd.com" className="text-blue-600">
                    {" "}
                    info@psksyd.com
                  </a>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p className="font-bold text-lg mb-2">
                    Ändringar i integritetspolicy{" "}
                  </p>
                  PSK förbehåller sig rätten att uppdatera eller ändra denna
                  policy när som helst och du bör regelbundet besöka vår hemsida
                  för att få den senaste versionen.
                  <br></br>
                  <br></br>
                  Senast uppdaterad: 2021-10-13
                </div>
              </div>
              <ProfileCardNoImg
                name="Malin Ohlsson"
                email="malin.ohlsson@psksyd.com"
              />
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Policy;
