import React from "react";

export default ({ name, email }) => (
  <div className="flex items-center mt-10 p-6">
    <div className="text-sm">
      <p className="text-gray-900 leading-none">{name}</p>
      <a className="text-gray-600" href={`mailto:${email}`}>{email}</a>
    </div>
  </div>
);
